.skus-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}
.search-wrapper {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-end;
	// padding: 0.5rem;

	.state-main {
		padding: 2px;
		display: flex;
		justify-content: center;

		.skus-search-box {
			min-width: 20rem;
			// min-width: 30rem;
		}
	}
}
.previewModal {
	max-width: 100vw;
	top: 0 !important;
	.ant-modal-content {
		padding: 5px 5px 0 !important;
	}
}

.skus-size-select {
	font-size: 15px;
	min-width: 2rem;
	font-weight: normal;
	padding: 3px 2px;
	height: 2rem;

	.skus-size-input {
		height: 2rem;
		min-width: 2rem;
		font-size: 15px;
		font-weight: 100;
		border-radius: 5px;
		border-width: 1px;
		margin: 0;
		width: 4.7rem;
		max-width: 4.7rem;

		.ant-slider-dot {
			width: 1px;
			border: 1px solid black;
			border-radius: 0;
		}

		.ant-slider-mark-text {
			color: black;
		}
		// overflow: auto;
	}
}

.skus_row{

.status-filters-input{
	width: 14rem;
}
}