.layout_container {
	width: 100%;
	height: 100vh;
	.layout_route_container {
		width: 100%;
		display: flex;

		// height: 100%;
		.sideBar-container {
			width: 215px;
			height: calc(100vh - 60px);
		}
		.route-container {
			width: calc(100vw - 215px);
			overflow: auto;
			height: calc(100vh - 60px);
		}
	}
	// .route_cont{
	// 	width: 100% !important;
	// 	height: 100% !important;
	// }
}
