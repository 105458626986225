.stats-wrapper {
	width: calc(100% - 10px);
	height: 35px;
	padding: 3px;
	background-color: #fdf4f5;
	margin-left: 5px;
	.state-main {
		width: 100%;
		height: 30px;
		// box-shadow: 1px 2px 15px -8px grey;
		// background-color: #fdf4f5;
		display: flex;
		justify-content: space-between;
		padding: 2px 4px;
		overflow: auto;
		.single-tag-wrapper {
			width: max-content;
			.single-tag {
				.tag-icon {
					background-color: white;
					border-radius: 50%;
					padding: 2px;
				}
			}
		}
	}
}
