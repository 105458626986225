@import "../../assets/scss/style.scss";
@import "../../assets/scss/variable.scss";
@import "../../assets/scss/mixin.scss";

.login-layout {
	position: relative;
	flex-wrap: nowrap;
	width: 100vw;
	height: 100vh;
	background-image: url("../../assets/images/login-image1.jpeg");
	background-size: 100% 100%;
	object-fit: cover;
	background-repeat: no-repeat;

	.login_left_logo_div {
		position: absolute;
		width: 100%;
		padding: 20px;
		height: 100px;
		.logo_img {
			height: 50px;
			width: 130px;
		}
		z-index: 1;
	}

	.main-panels-div {
		position: relative;
		width: 80%;
		height: 80%;
		background-color: rgb(255, 255, 255);
		border-radius: 6px;
		overflow: hidden;
		@include flex-center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		& > div {
			height: 100%;
			// div{
			// 	height: inherit;
			// }
		}
		.login_left_background_container {
			.login_left_foreground_div {
				height: 100%;
				img {
					height: 100%;
					object-fit: cover;
					max-width: 100%;
				}
			}
		}

		@media only screen and (max-width: 961px) {
			.login_left_background_container {
				width: 0;
				display: none;
			}
		}
		.login_right_background_container {
			padding: 24px;
			text-align: center;
			overflow: auto;
			.login_right_foreground_div {
				// text-align: center;

				.logo_img {
					height: 5vh;
					width: auto;
					object-fit: contain;
					margin-bottom: 1.5rem;
				}
				.login_divider {
					margin: 10px 0 !important;
				}
			}
		}

		@media only screen and (max-width: 961px) {
			.login_right_background_container {
				padding: 5px;
			}
		}

		// .login_left_background_container {
		// 	// position: relative;
		// 	// background-image: url("../../assets/images/login-image1.jpeg");
		// 	// background-size: 100% 100%;
		// 	// object-fit: cover;
		// 	// background-repeat: no-repeat;
		// 	// border-top-right-radius: 50px;
		// 	.login_left_foreground_div {
		// 		height: 100%;
		// 		width: 100%;
		// 		display: flex;
		// 		flex-direction: column;
		// 		justify-content: flex-start;
		// 		align-items: center;
		// 		padding-top: 25%;
		// 		gap: 15px;
		// 		.login_foreground_image {
		// 			height: 50%;
		// 			width: 70%;
		// 			object-fit: cover;
		// 		}

		// 		.foreground_text {
		// 			width: 100%;
		// 			text-align: left;
		// 			padding-left: 15%;
		// 			padding-right: 15%;
		// 			padding-top: 2%;
		// 			backdrop-filter: blur(60px);
		// 			.ant-typography.text_first_row {
		// 				font-size: 1.8rem;
		// 				line-height: 3rem;
		// 				font-weight: 500;
		// 				text-shadow: 1px 1.4px black;
		// 				letter-spacing: 1px;
		// 				color: white;
		// 			}

		// 			.ant-typography.text_second_row {
		// 				font-size: 2rem;
		// 				line-height: 3rem;
		// 				font-weight: 500;
		// 				text-shadow: 1px 1.4px black;
		// 				letter-spacing: 1px;
		// 				margin: 0;
		// 				color: white;
		// 			}
		// 			.ant-typography.text_second_row::first-letter {
		// 				font-size: 4rem;
		// 				font-weight: 700;
		// 				color: black;
		// 				text-shadow: 0.5px 0.5px white;
		// 			}
		// 		}
		// 	}
		// }

		// .login_left_background_container {
		// 	.login_right_foreground_div {
		// 		height: 100%;
		// 		width: 100%;
		// 		display: flex;
		// 		flex-direction: column;
		// 		justify-content: flex-start;
		// 		align-items: center;
		// 		background-color: blue;
		// 	}
		// }
	}
}
