
.registerForm{
    .ant-form-item-explain-error{
        text-align: left;
    }
    .clients_selector{
        .ant-space-item:nth-child(1){
            width: 100%;
        }
    }
    .ant-form-item{
        margin-bottom: 18px ;
    }
    // .ant-form-item-control-input-content{
    //     height: 5vh;
    //     .ant-input, .ant-input-number-input, .ant-select-selector, .ant-space, .ant-input-number, .ant-input-number-input-wrap, .ant-space-item,.ant-select, .ant-btn{
    //         height: 100%;
    //     }
    // }
}


