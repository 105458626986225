.company_sidebar_container {
	display: flex;
	flex-direction: column;
	border-right: 1px solid #dbdada;
	align-items: center;
	gap: 20px;
	padding: 20px 5px 5px;
	height: calc(100vh - 60px);
	overflow-y: auto;
	width: 100%;

	.company_cards {
		height: 6vh;
		width: 80%;
		.ant-card-body {
			height: 100%;
			padding: 15px !important;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.ant-card-body {
		height: 100%;
		padding: 15px !important;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.company_cards_selected {
		border: 1px solid #3a3737 !important;
		height: 6vh;
		width: 100%;
	}
	.company_cards {
		height: 6vh;
		width: 100%;
	}
}
