.canvas-instance-root {
	width: 100%;
	display: flex;
	flex-grow: 1;
	height: 100%;

	.canvas-section {
		display: flex;
		flex-grow: 1;
		padding-top: 10px;
		padding-right: 0;
		width: 100%;
		max-width: calc(100% - 20px);
		.preview-left,
		.preview-right {
			padding-right: 0;
			width: calc(100% / 2);
			/* height: 100%; */
			display: flex;
			flex: 1;
			flex-direction: column;

			.canvasComponent-container {
				flex: 1;
			}
		}

		.preview-right {
			border-left: 1px solid #aaa;
		}
	}

	.divtest1 {
		height: 100%;
		background-color: grey;
		width: 28px;
		position: relative;

		.divtest2 {
			height: 2vh;
			background-color: green;
			width: 20px;
			position: absolute;
			border-radius: 50%;
		}

		.divtest3 {
			height: 2vh;
			background-color: red;
			width: 20px;
			position: absolute;
			border-radius: 50%;
		}

		.divtest4 {
			height: 2vh;
			background-color: blue;
			width: 20px;
			position: absolute;
			border-radius: 50%;
		}
		.div-test-hr {
			width: 62vw;
			position: relative;
			left: -62vw;
			top: 0px;
			max-width: 100vw;
		}
	}

	.annotation-tools {
		width: calc(18% - 20px);
	}
}
