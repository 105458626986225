.fileAction-main{
    height: 100%;
    .file_arrows{
        height: 35px;
    width: 40px;
    font-size: 18px;
    border-radius: 6px;
    transition: transform 0.2s, background-color;
    cursor: pointer;
       &:hover{ 
        
        background-color: rgba(202, 202, 202, 0.55);
        transition: 0.5s;
    }
    &:active{ 
        
        background-color: rgba(128, 128, 128, 0.55);
    }
    }
    .reject_btn {
        .ant-btn-icon{
            transform: scaleX(-1);
        }
    }
    .action_btn{
        font-size: 0.75rem;
        padding: 2px 10px;
    }
    .ant-tooltip-open{
        background-color: #d8737391;
        color:rgba(255, 0, 0, 0.693);
        opacity: 0.4;
    }
    .rejected_btn_type{
        background-color: #ff838391;
        color:rgba(115, 2, 2, 0.852);
        opacity: 0.8;
    }
    .accepted_btn_type{
        background-color: #81ff816d;
        color: rgba(0, 128, 0, 0.797);
    }
    .glow-on {
        border: none;
        outline: none;
        color: black;
        background: inherit;
        cursor: pointer;
        position: relative;
        z-index: 0;
    }
    .glow-on:before {
        content: '';
        background: linear-gradient(45deg,#ff6c6c, #ff7a7a, #f67777, #ffc0c0, #ffffff, #ffc0c0, #f67777, #ff7a7a, #ff6c6c);
        position: absolute;
        top: 0;
        left:0;
        background-size: 400%;
        z-index: -1;
        // filter: blur(1px);
        width: 100%;
        height: 100%;
        animation: glowing 20s linear infinite;
        opacity: 1;
        transition: opacity .3s ease-in-out;
        border-radius: 6px;
    }
    .glow-on:after {
        z-index: -1;
        content: '';
        position: relative;
        width: 100%;
        height: 100%;
        background: inherit;
        // left: 0;
        // top: 0;
        border-radius: inherit;
    }
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}