.users-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;

	.filters-row-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;

		.invite-button {
			padding: 0;
			width: fit-content;
			height: fit-content;
			.invite-button-tag {
				margin: 0;
			}
			.invite-button-tag:hover {
				background-color: #5184c4;
				color: white;
			}
			.invite-button-tag:active {
				background-color: lightblue;
				color: white;
			}
		}
	}

	.users-list-wrapper {
		width: 100%;
		position: relative;
		height: calc(100vh - 165px);
		padding: 10px;
	}

	.invite-user-model {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
}
