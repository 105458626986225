@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.file-preview-container {
	width: 100%;
	// height: calc(100vh - 60px);
	height: 100%;
	display: flex;
	flex-direction: column;

	.preview-header {
		padding: 10px;
	}

	.canvas-container-preview {
		width: 100%;
		padding: 2px;
		display: flex;
		flex-direction: column;
		// height: calc(100vh - 106px);
		height: 100%;

		.canvas-left {
			height: fit-content;
			width: fit-content;
			background-color: rgba(221, 221, 221, 0.475);
			position: absolute;
			top: 40%;
			left: 0;
			z-index: 1;
		}

		.canvas-top {
			width: 100%;
			background-color: white;
			display: flex;
			flex-wrap: nowrap;
			height: 90%;

			.preview-images-block {
				width: 100%;
				display: flex;
				flex-direction: row;

				.preview-instances-images {
					width: 100%;
					background-color: white;
					position: relative;

					.slidecontainer {
						// transform: rotate(90deg);
						position: absolute;
						background: transparent;
						// width: 70%;
						height: 100%;
						top: 0;
						left: 0;
						width: 100%;
						max-width: calc(80% - 20px);

						.slider {
							appearance: none;
							width: 100%;
							height: 100%;
							outline: none;
							opacity: 0.7;
							transition: opacity 0.2s;
							background: transparent;
						}

						.ant-slider .ant-slider-handle {
							width: 100%;
						}

						.ant-slider .ant-slider-handle::after {
							background-color: red;
							width: calc(100% - 20px);
							border-radius: 0;
							box-shadow: none;
							height: 2px;
						}

						// .slider:hover {
						// 	opacity: 1;
						// }

						// .slider::-webkit-slider-thumb {
						// 	-webkit-appearance: none;
						// 	appearance: none;
						// 	width: 1px;
						// 	// height: 100vw;
						// 	background: red;
						// 	cursor: pointer;
						// }

						// .slider::-moz-range-thumb {
						// 	width: 1px;
						// 	// height: 100vw;
						// 	background: red;
						// 	cursor: pointer;
						// }
					}
				}
			}
		}

		.canvas-bottom {
			width: 100%;
			height: 10%;
			display: flex;
			// flex-direction: row;
			// flex-wrap: nowrap;
			border-top: 1px solid black;
			padding: 2px 0 3px;
			justify-content: space-evenly;
			align-items: center;
			gap: 50px;
			background-color: rgba(221, 221, 221, 0.475);

			.rejection-comments-container {
				width: 42%;
				height: 100%;

				// background-color: green;
				// overflow: auto;
				// border-right: 1px solid  black;
				// div {
				// 	height: 100%;
				// }
			}

			.similar-images-container {
				width: 35%;
				height: 100%;
				// background-color: yellow;
				// div{
				// 	height: 100%;
				// }
			}

			.file_details_info {
				gap: 0 !important;
				width: 170px;
			}
		}
	}
}