@import "../../../assets/scss/mixin.scss";
@import "../../../assets/scss/variable.scss";

.batch-carousel {
	margin-left: 2rem;
	// width: 75%;
	// background-color: blueviolet;
	justify-content: flex-start;

	// .thumb_item {
	// 	background-color: white;
	// 	cursor: pointer;
	// 	.carousel_img {
	// 		width: 100%;
	// 		height: 100%;
	// 		object-fit: contain;
	// 	}
	// }
	.sibligCarousel {
		width: 36vw;
		gap: 8px;
		max-width: 36vw;
		overflow-x: auto;
		overflow-y: hidden;
		padding: 0 2px;
		// background-color: indianred;

		// & {
		// 	-ms-overflow-style: none;
		// 	/* Internet Explorer 10+ */
		// 	scrollbar-width: none;
		// 	/* Firefox */
		// }

		// &::-webkit-scrollbar {
		// 	display: none;
		// 	/* Safari and Chrome */
		// }
	}

	.thumb_item {
		background-color: white;
		cursor: pointer;
		border: 1px solid rgb(188, 188, 188);
		;
		position: relative;
		min-width: 5.5vw;
		max-width: 7vw;
		height: 9vh;

		.carousel_img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.alice-carousel__stage-item {
		// margin: 0 1px;
		border: 1px solid rgb(188, 188, 188);
	}

	//      .alice-carousel__stage-item:first-child {
	//     margin-left: 0;
	//     }
	.alice-carousel__stage-item.__target,
	.active_cItem {
		border: 1px solid red;
	}

	.alice-carousel {
		margin: 0;
		width: 60%;
	}

	.custom_arrows {
		width: 25px;
		background-color: rgba(202, 202, 202, 0.55);
		font-size: 22px;
		cursor: pointer;
		margin: 0 5px;

		&:hover {
			background-color: rgba(151, 151, 151, 0.55);
			transition: 0.3s;
		}

		&:active {
			background-color: rgba(116, 116, 116, 0.55);
		}
	}

	.disable_arrow {
		opacity: 0.4;
		cursor: not-allowed;
		pointer-events: none;
	}

	.starIcon {
		position: absolute;
		top: 3px;
		right: 3px;
		font-size: 14px;

		svg {
			path {
				&:nth-child(1) {
					fill: rgb(255, 204, 0);
				}

				&:nth-child(2) {
					fill: green;
				}
			}
		}
	}

	.starIcon_siblings {
		position: absolute;
		top: 3px;
		left: 3px;
		font-size: 14px;
		height: 14px !important;
		width: 14px;
	}
}

.activeSibling {
	.alice-carousel {
		.alice-carousel__stage-item.__active {
			border: none;
		}
	}
}