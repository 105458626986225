.sidebar-main {
	height: calc(100vh - 60px);
	width: 100%;

	.custom_Sider {
		width: 100% !important;
		max-width: 100% !important;

		.side_bar_menu {
			width: 100%;
		}
	}
}
