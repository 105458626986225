.batch-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}
.previewModal{
max-width: 100vw;
	top:0 !important;
	.ant-modal-content{
		padding: 5px 5px 0 !important;
	}
}
@media screen and (max-width:1707px) {
	.batch-wrapper{
		.header-wrapper{
			height: max-content;
		}
	}
}
