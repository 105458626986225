.filters-row-wrapper {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-end;
	padding: 0.5rem;
	.batch-name-search {
		font-size: 15px;
		min-width: 5rem;
		font-weight: normal;

		.name-filter {
			height: 2rem;
			font-size: 15px;
		}
	}

	.grid-list {
		display: flex;
		width: max-content;
		padding: 2px;
		height: 2rem;

		.grid-list-button {
			padding: 0 4px;
			max-height: 100%;
			// border: 1px solid black;
			border-radius: 0;
			box-shadow: 0 0 2px 0 black;
		}
	}

	.status-filters-wrapper {
		font-size: 15px;
		min-width: 2rem;
		font-weight: normal;
		padding: 0;
		height: 2rem;

		.status-filters-input {
			height: 2rem;
			min-width: 2rem;
			font-size: 15px;
			font-weight: 100;
			border-radius: 5px;
			border-width: 1px;
			margin: 0;
			width: 14rem;
			max-width: 14rem;
			// overflow: auto;
		}
	}
}
