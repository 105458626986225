@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/variable.scss";
.dashboard-wrapper-main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.dashboard-search-box {
		margin: 25px 0;
		padding: 0 25px;
		.name-filter {
			width: 18rem;
			float: right;
		}
	}

	.filters_row {
		display: flex;
		width: 100%;
		height: 50px;
		align-items: flex-end;
		padding: 0 10px;
		// padding-bottom: 10px;
		.page_title {
			width: max-content;

			height: 100%;
			display: flex;
			align-items: flex-end;
		}

		.filters_div {
			flex-grow: 1;
			display: flex;
			justify-content: flex-end;
			.batch-name-search {
				font-size: 15px;
				min-width: 5rem;
				font-weight: normal;

				.name-filter {
					height: 2rem;
					font-size: 15px;
				}
			}

			.status-filters-wrapper {
				font-size: 15px;
				min-width: 2rem;
				font-weight: normal;
				padding: 0;
				height: 2rem;

				.status-filters-input {
					height: 2rem;
					min-width: 2rem;
					font-size: 15px;
					font-weight: 100;
					border-radius: 5px;
					border-width: 1px;
					margin: 0;
					width: 14rem;
					max-width: 14rem;
					// overflow: auto;
				}
			}
		}
	}
}

.fixed_height {
	height: calc(100vh - 130px);
	overflow-y: auto;
}

.dashboard-table {
	flex-grow: 1;
}

.dashboard-pagination {
	height: 50px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
