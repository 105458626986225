@import "../../../assets/scss/mixin.scss";

.tool-container-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;

	// background-color: lightblue;
	.annotation_btns {
		padding: 4px;
	}
}

.tool-bar {
	width: auto;
	background-color: lightblue;
}

.tool-bar-ul {
	width: 100%;
	list-style: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
	// padding-top: 10px;
	padding: 4px;

	.tool-bar-item {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.tool_icons {
		font-size: 1.4rem;
		height: 1.4rem;
		// object-fit: contain;
		max-width: 25px;
	}
}

.tool-comment-bar {
	width: 100%;
	// height: 100%;
	height: 95%;
	background-color: rgba(221, 221, 221, 0.475);
	padding: 5px 15px 15px;

	.comment-editor-box {
		margin-top: 5px;

		.ant-space-item {
			width: 100%;
		}
	}

	// .comments_container {
	// 	overflow-y: auto;
	// 	height: 45vh;
	// }

	.noHeightComment {
		height: 0 !important;
	}

	.metaInfo_box {
		// height: 30%;
		height: 25%;
		margin-bottom: 5px;
		// overflow-y: auto;
	}

	.underMetaBox {
		height: 60%;
		overflow-y: auto;

		& {
			-ms-overflow-style: none;
			/* Internet Explorer 10+ */
			scrollbar-width: none;
			/* Firefox */
		}

		&::-webkit-scrollbar {
			display: none;
			/* Safari and Chrome */
		}
	}

	.underMetaOff {
		overflow-y: auto;
		height: 85%;

		& {
			-ms-overflow-style: none;
			/* Internet Explorer 10+ */
			scrollbar-width: none;
			/* Firefox */
		}

		&::-webkit-scrollbar {
			display: none;
			/* Safari and Chrome */
		}
	}

	.ant-descriptions-row>td {
		padding-bottom: 1px;
	}

	.keyboardIcon {
		height: 4vh;
		width: auto;
		cursor: pointer;
	}
}

.metaModal {
	top: 25px !important;
}

#pickerInput {
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

@media screen and (max-width:1550px) {
	.tool-comment-bar {

		// .comments_container {
		// 	height: 37vh;
		// }
	}
}