.preview-root {
  display: flex;
  align-items: flex-start;
  min-height: 100%;
  max-height: 100%;
  width: 100vw;
  overflow-x: hidden;
  flex-direction: column;
}

.preview-left, .preview-right {
  width: calc(100%/2);
  /* height: 100%; */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.preview-right {
  border-left: 1px solid #aaa;
}

.preview-icon {
  cursor: pointer;
}

.fpm-modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}

.fpm-modal-header > span {
  padding: 5px 10px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.fpm-accept-btn {
  color: #fff;
  background: green;
}

.fpm-reject-btn {
  color: #fff;
  background: red;
}

.fpm-close{
  color: #fff;
  font-weight: bold;
  border: 1px solid white;
}

.canvas-instance-root {
  /* width: 100vw;
  display: flex; */
  flex-grow: 1;
}

.preview-appbar {
  background-color: #fff;
}

.preview-actions {
  display: flex;
  justify-content: center;
}

.dialog-content-root {
  padding: 0;
}

.preview-canvas-container {
  display: flex;
  flex: 1;
  /* height: 100%; */
}

.preview-action {
  margin: 0 1vw;
}

.canvasComponent-container {
  flex: 1;
}

.canvas-section {
  display: flex;
  flex-grow: 1;
}

.canvasActions-root {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.similar-files {
  display: flex;
  height: 100%;
}

.similar-file {
  width: 75px;
  height: 100%;
  border: 1px solid black;
  margin: 0 10px;
  cursor: pointer;
}

.similarImgThumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.canvas-instance-drawer {
  background-color: red;
}

.slidecontainer {
  
   /* transform: rotate(90deg);  */
  /* position: absolute;
  background: white;  */

  position: absolute;
  background: transparent;
  width: 70%;
  /* width: 2px; */

  /* bottom: 10vh; */
  height: 100%;
  top: 18vh;
  /* right: 615px; */

}

.slider {
  /* -webkit-appearance: none;
  width: 70%;
  height: 100%;
  
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  background:  transparent; */
  -webkit-appearance: none;
  width: 70%;
  height: 100%;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  background: transparent;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1px;
  height: 100vw;
  background: red;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 1px;
  height: 100vw;
  background: red;
  cursor: pointer;
}

/* .combo-box-demo{
  width: "100%";
   margin: "10px 0"
} */

.display-change {
  display: flex;
  height: 100%;
}

.testing-div  {
  /* background-color: red; */
  margin-top: -2px;
  border: 2px solid red;
}

.slider2 {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  transform: rotate(90deg); 
}

.slider2:hover {
  opacity: 1;
}

.slider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider2::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.divtest1 {
  height:80vh; 
   background-color:grey;
    width:20px;
    position: relative;
}

.divtest2 {
  height:2vh;  
  background-color:green; 
  width:20px;
  position: absolute;
  border-radius: 50%;
}

.divtest3 {
  height:2vh;  
  background-color:red; 
  width:20px;
  position: absolute;
  border-radius: 50%;
}

.divtest4 {
  height:2vh;  
  background-color:blue; 
  width:20px;
  position: absolute;
  border-radius: 50%;
}
