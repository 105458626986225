@import '../../../assets/scss/mixin.scss';
@import '../../../assets/scss/variable.scss';

.comment_cards{
    position: relative;
    .ant-card-head{
        min-height: 30px !important;
    }
    .ant-card-body{
        padding: 5px 12px !important;
        .ant-space{
            gap: 4px !important;
        }
        .time_box{
            gap: 1px !important;
        }
        span{
            font-size: 12px;
        }
    }
    .delete_icon{
        position: absolute;
        top: 5px;
        right: 8px;
    }
    
}