
.dynamic-drawer-filters{
    .ant-drawer-body{
        overflow: hidden !important;
        justify-content: space-between;
    display: flex;
    flex-direction: column;
        .dynamic-filters-wrapper{
            overflow-y:auto;
        }
    }
}
