.grid-form-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;

	.grid-form {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		padding: 10px 10px;
		gap: 10px;

		.sides-input {
			width: "100%";
			display: flex;
			flex-direction: column;
			.select-sides {
				width: 100%;
			}
		}

		.sides-pixels-input {
			width: "100%";
			display: flex;
			flex-direction: column;
			.select-pixels {
				width: 100%;
			}
		}
		.form-submit-button {
			width: "100%";
			display: flex;
			flex-direction: column;
			.draw-grid-lines-button {
				width: 100%;
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	.drawn-values {
		display: flex;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		padding: 10px 10px;
		gap: 10px;

		.description-container {
			width: 100%;
			display: flex;

			.ant-descriptions-row .ant-descriptions-item-label {
				max-width: 40%;
				width: 25%;
				min-width: 20%;
			}
			.ant-descriptions-row .ant-descriptions-item-content {
				display: flex;
				flex-wrap: wrap;
			}
			.ant-tag-hidden {
				visibility: visible;
				display: inline;
			}
			.key-container {
				background-color: rgb(246, 250, 250);
			}
		}
	}
}
