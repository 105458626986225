//colors
$white: #ffffff;
$primary: #5184c4;
$bgColor: #fcf8ef;
$deepBlue: #005589;
$grey: #d3d3d3;
$darkBlue: #005589;
$red: #ff0000;
$green: #2a967c;
$lightyello: #f9f0dd;
$lightBlack: #333333;
$orange: #faad14;
$black: #000;
$blue: rgb(60, 180, 250);

//font sizes
$thirty: 30px;
$twentytwo: 22px;
$twenty: 20px;
$eighteen: 18px;
$sixteen: 16px;
$fourteen: 14px;
$thirteen: 13px;
$twelve: 12px;
$ten: 10px;
$eight: 8px;
$oneEm: 1em;
$twoEm: 2em;

//radius
$radius: 8px;

//box-shadow
$card-box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.15);

// Typography
$FONT_SIZE_XXS: 0.5rem; // 8px
$FONT_SIZE_XS: 0.625rem; // 10px
$FONT_SIZE_S: 0.75rem; // 12px
$FONT_SIZE_M: 0.875rem; // 14px
$FONT_SIZE_L: 1rem; // 16px
$FONT_SIZE_XL: 1.5rem; // 24px
$FONT_SIZE_XXL: 1.675rem; // 28px

// Spacing
$SPACING_XS: 0.25rem; // 4px
$SPACING_S: 0.5rem; // 8px
$SPACING_M: 0.75rem; // 10px
$SPACING_L: 1.25rem; // 15px
$SPACING_XL: 1.5rem; // 25px
$SPACING_XXL: 2rem; //32px
$SPACING_XXLL: 2.5rem; // 40px
$SPACING_XXXL: 3.5rem; //56px

//font-family
$roboto: "Roboto", sans-serif;
$montserrat: "Montserrat", sans-serif;
