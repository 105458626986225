@import "./assets/scss/mixin.scss";
@import "./assets/scss/variable.scss";

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.default-btn-primary {
	background-color: $primary;
	text-transform: capitalize;
	// color: $white;
}
.default-btn-primary:hover {
	background-color: $primary !important;
	text-transform: capitalize;
	// color: $white;
}
// .default-btn-default {
// 	border-color: $primary;
// 	color: $primary;
// }

.common_input_width {
	width: 350px;
}
.google_btn {
	padding: 0 !important;
	border: none;
	display: inline-flex;
	text-transform: uppercase;
	background-color: $primary !important;
	.googleImg_container {
		width: 20%;
		margin: 0;
		height: 100%;
		border-radius: 8px 0 0 8px;
		img {
			height: 35%;
		}
	}
	span {
		width: 80%;
		text-align: center;
		height: 100%;
		@include flex-center;
		font-size: $FONT_SIZE_M;
	}
}
.google_btn:hover {
	background-color: $primary !important;
}

.error_txt {
	color: red;
	font-size: smaller;
	margin: 5px;
}
.input_container {
	position: relative;
}

.forget-password-div {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding: 0;

	.forget-passord-button {
		padding: 0;
		color: black;
		font-size: 11px;
	}
	.forget-passord-button:hover {
		padding: 0;
		color: $primary;
	}
}

.forgot-password-modal {
	padding: 1px;

	.requestForgotButton {
		background-color: $primary;
	}
}

.login_divider {
	.ant-divider-inner-text {
		font-size: 11px;
	}
}
