.raw-images-wrapper {
	width: 100%;
	height: 100%;
	padding: 0px 0 0 4px;
	position: relative;

	.top-space {
		width: 100%;
		position: relative;
		.left_thumb_item {
			// width: 100%;

			height: 9vh;
			padding: 0;
			.left_carousel_img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}
