.files-filters-row-wrapper {
	display: flex;
	justify-content: flex-end;
	height: 100%;
	align-items: flex-end;
	padding: 0.5rem;
	flex-wrap: wrap;
	row-gap: 4px;
	.file-name-search {
		font-size: 15px;
		min-width: 5rem;
		font-weight: normal;

		.name-filter {
			height: 2rem;
			font-size: 15px;
		}
	}

	.status-filters-wrapper {
		font-size: 15px;
		min-width: 2rem;
		font-weight: normal;
		padding: 0;
		height: 2rem;

		.status-filters-input {
			height: 2rem;
			min-width: 2rem;
			font-size: 15px;
			font-weight: 100;
			border-radius: 5px;
			border-width: 1px;
			margin: 0;
			width: 14rem;
			max-width: 14rem;
			// overflow: auto;
			
		}
	}
	.file-size-select {
		font-size: 15px;
		min-width: 2rem;
		font-weight: normal;
		padding: 3px 2px;
		height: max-content;
		// height: 2rem;

		.file-size-input {
			height: max-content;
			// height: 2rem;
			min-width: 2rem;
			font-size: 15px;
			font-weight: 100;
			border-radius: 5px;
			border-width: 1px;
			margin: 0;
			width: 4.7rem;
			max-width: 4.7rem;

			.ant-slider-dot {
				width: 1px;
				border: 1px solid black;
				border-radius: 0;
			}

			.ant-slider-mark-text {
				color: black;
			}
			// overflow: auto;
		}
	}

	.filters-button {
		height: 2rem;
		padding: 2px 8px;
	}

	.dynamic-drawer-filters {
		min-width: 35rem;

		.dynamic-filters-wrapper {
			width: 100%;
			padding: 20px;
		}
	}
}

@media screen and (max-width:1536px){
	.files-filters-row-wrapper {
		width: 49rem !important ;
		.status-filters-input{

			width: 10rem !important;


		}
	}
}
@media screen and (max-width:1707px){
	.files-filters-row-wrapper {
		width: 53rem;
		
	}
}