@import "../../assets/scss/variable.scss";

.header_main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: -1px 0px 5px 0px #000000ab;
	padding: 10px 35px;
	position: relative;
	z-index: 9;
	background-color: $white;
	height: 60px;

	.logo_img {
		padding-bottom: 5px;
		height: 45px;
	}

	.avatar_container {
		position: relative;
		display: flex;
		align-items: center;
	}
}

.profileMenu {
	min-width: 120px;
}
