.header-wrapper {
	width: 100%;
	height: 100px;
	box-shadow: 0px -1px 2px 0px #000000ab;
	padding: 10px 10px 5px 10px;
	.main-div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: 100%;

		.left_container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.breadcrumb-div {
				font-size: 0.9rem;
			}
			.title-div {
				.typo-title {
					padding: 0;
					margin: 0;
					font-size: 1.5rem;
					font-weight: 500;
					padding-bottom: 0.5rem;
					opacity: 0.8;
					height: 3rem;
				}
			}
		}
		.right-container {
			flex-grow: 1;

			.skus_row {
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: flex-end;
				height: 100%;
				padding: 0.5rem;

				.skus_row_element {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					height: 2rem;
				}
			}
		}
		.header_btn_class{
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
}

.downloadIcon{
	// filter: drop-shadow(0 0 2px #72a94e);
	animation: glow 1.2s alternate infinite;
	&:hover{
		animation: none;
	}
}

@keyframes glow{
	0%{
		// filter: drop-shadow(0 0 1px #e0ffcc);
		transform: scale(1);
	}
	100%{
		// filter: drop-shadow(0 0 2px #89c163);
		transform: scale(1.2);
	}

}