.skus-list-wrapper {
	width: 100%;
	position: relative;
	height: calc(100vh - 165px);

	.skus-tabular-data-div {
		height: 94%;
		width: 100%;
		// overflow: auto;
		display: flex;

		.collection-view-container {
			width: 98%;
			height: 100%;
			display: flex;
			flex: 1;
			padding: 0 15px 10px 0;

			.collection-class {
				margin: 5px 0 0 14px;
				display: flex;
				flex-wrap: wrap;
				overflow: auto;
				height: 100%;

				.single-thumbnail-folder {
					width: 225px;
					height: 260px;
				}
			}
		}

		.skus-view-wrapper {
			// width: 225px;
			// height: 260px;
			position: relative;
			display: flex;
			justify-content: center;

			.skus-card-main {
				position: relative;

				.ant-card-body {
					padding: 0px;
					height: inherit;

					.filename-typo {
						position: relative;
						display: block;
						height: 100%;
						width: 100%;
						// max-width: 210px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						text-align: center;
					}

					.loader-class {
						width: 100%;
						height: 100%;
						// padding: 30px;
					}
				}
				
			}
			.selectedItem{
				border: 1px solid rgb(32, 129, 255);
				img,div{
					border-radius: inherit;
				}
			}
			.meta-info-btn{
				position: absolute;
				top: 5px;
				right: 8px;
			}
		}
	}

	.pagination-div {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 5%;
	}
}
