.batch-list-wrapper {
	width: 100%;
	position: relative;
	height: calc(100vh - 165px);

	.list-tabular-data-div {
		height: calc(100vh - 220px);
		// width: 100%;
		overflow: auto;
		display: flex;

		.table-view-container,
		.collection-view-container {
			// width: 98%;
			// height: 100%;
			// overflow: auto;
			// display: flex;
			// flex: 1;
			// padding: 15px;
			width: 100%;
			height: 100%;
			display: flex;
			flex: 1;
			padding: 0 15px 10px 0;
			overflow: auto;

			.main-virtualised-table-container {
				height: 98%;
				width: 98%;
				padding: 8px;
				// border-collapse: collapse;
				border-width: 1px solid black;

				.header-class {
					display: flex;
					justify-content: center;
				}

				.row-cell-class {
					display: flex;
					justify-content: center;
				}

				.row-cell-class-first {
					justify-content: flex-start;
				}
			}

			.collection-class {
				margin: 5px 0 0 14px;
				display: flex;
				flex-wrap: wrap;
				overflow: auto;
				height: 100%;

				// .ReactVirtualized__Collection__innerScrollContainer {
				// 	display: flex;
				// 	overflow: auto;
				// 	flex-wrap: wrap;
				// }
				.single-thumbnail-folder {
					width: 180px;
					height: 180px;
				}
			}
		}

		.folder-view-wrapper {
			width: 180px;
			height: 300px;
			position: relative;
			display: flex;
			justify-content: center;

			.folder-card-main {
				position: relative;

				.ant-card-body {
					padding: 10px;
					height: 200px;

					.loader-class {
						width: 100%;
						height: 100%;
					}
				}

				.batchesName {
					// display: block;
					white-space: break-spaces;
					overflow: hidden;
					text-align: center;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					border: 4px solid red;
				}
			}
		}
	}

	.pagination-div {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
	}
}

.batch-list-status {
	box-shadow: 1px 1px 5px #777;
	padding: 5px;
}

.batch-card {
	margin: 10px;
	padding: 5px;
} 
.batch-card:hover {
	box-shadow: 2px 2px 5px #ccc;
	
}