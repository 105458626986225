.model-footer {
	display: flex;
	justify-content: flex-end;
}

.draggable-div {
	min-width: 15%;
	max-width: 100%;
	width: 60%;
	max-height: 600px;
	overflow: auto;
}
