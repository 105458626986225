@import "./mixin.scss";
@import "./variable.scss";

* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	font-family: $roboto;
}
.linkText {
	@include link-text;
}
/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #555;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.color-primary {
	color: $primary !important;
}
.color-secondary {
	color: $deepBlue;
}
.colorRed {
	color: $red;
}
.f-500 {
	font-weight: 500;
}
.m-auto {
	margin: auto;
}
.mx-auto {
	margin: 0 auto;
}
.my-auto {
	margin: auto 0;
}
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mt-3 {
	margin-top: 3rem;
}
.mt-4 {
	margin-top: 4rem;
}
.mt-5 {
	margin-top: 5rem;
}
.mt-6 {
	margin-top: 6rem;
}
.mb-1 {
	margin-bottom: 1rem;
}
.mb-2 {
	margin-bottom: 2rem;
}
.mb-3 {
	margin-bottom: 3rem;
}
.mb-4 {
	margin-bottom: 4rem;
}
.mr-1 {
	margin-right: 4px;
}
.mr-2 {
	margin-right: 8px;
}
.mr-3 {
	margin-right: 12px;
}
.mr-4 {
	margin-right: 24px;
}
.ml-1 {
	margin-left: 4px;
}
.ml-2 {
	margin-left: 8px;
}
.ml-3 {
	margin-left: 12px;
}
.ml-4 {
	margin-left: 24px;
}

.m-10 {
	margin: 10px;
}

.px-1 {
	padding: 0 4px;
}
.px-2 {
	padding: 0 8px;
}
.px-3 {
	padding: 0 12px;
}
.px-4 {
	padding: 0 24px;
}

.py-1 {
	padding: 4px 0;
}
.py-2 {
	padding: 8px 0;
}
.py-3 {
	padding: 12px 0;
}
.py-4 {
	padding: 24px 0;
}

.card-selected {
	border: 1px solid $deepBlue !important;
}
.display-none {
	display: none;
}
.display-hidden {
	visibility: hidden;
	pointer-events: none;
}
.text-center {
	text-align: center;
}

.card-data-spacing {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.d-flex {
	display: flex;
}
.flexAlign-center {
	display: flex;
	align-items: center;
}
.container-block {
	// padding: 25px;
	padding: 50px 25px 25px;
}
.flexBox {
	display: flex;
	justify-content: left;
	flex-wrap: wrap;
	gap: 20px;
}
.ant-spin-spinning {
	color: black;
	margin-top: 10%;
}
.w-100 {
	width: 100%;
}
.w-75 {
	width: 75%;
}
.w-80 {
	width: 80%;
}
.w-90 {
	width: 90%;
}
.icon-space {
	padding: 1rem;
	cursor: pointer;
}
.break-white-space {
	white-space: break-spaces !important;
}
.text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.font-size-m {
	font-size: 12px;
}

.model-img {
	aspect-ratio: 1;
	height: 20vh;
	// border: 1px solid grey;
	display: flex;
	gap: 10px;
	// width: 7vw;
	justify-content: center;
	img {
		height: 100%;
	}
}

.styling_comment {
	border: 1px solid black;
	padding: 25px 10px;
	// width: 7vw;
	text-align: center;
}

.spin-center {
	position: absolute;
	top: 50%;
	left: 50%;
}
.dialogue-spin-center {
	position: absolute;
	top: 32%;
	z-index: 9;
	left: 50%;
}
.row-end {
	text-align: end;
}

.common-table-wrapper {
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
}

.span-font-size {
	font-size: 13px;
}

.success_button {
	border-color: green;
	color: green;
}

.reject_button {
	color: $red;
	border-color: $red;
}

.icon_legend {
	display: flex;
	align-items: center;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.w-50 {
	width: 50% !important;
}
.uppercase {
	text-transform: uppercase;
}
.uppercase:hover {
	text-transform: uppercase;
}
.flex-space_between {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.flex-space_evenly {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.card-spacing-element {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
}

.padding-2 {
	padding: 2rem;
}
.padding-1 {
	padding: 1rem;
}
.padding-left-4 {
	padding-left: 4rem;
}

.card-spacing-vertical {
	display: flex;
	flex-direction: column;
	gap: 15px;
	align-items: center;
	padding: 2rem;
}

.card-spacing-design {
	border: 1px solid grey;
	border-radius: 20px;
	padding: 20px;
}

.icon_size_m {
	font-size: 2.5rem;
	color: #de6b32;
	transform: rotate(271deg);
}

.icon_size_l {
	font-size: 2rem;
	color: #de6b32;
	cursor: pointer;
}

.text-left {
	text-align: left;
}

.justify-center {
	display: flex;
	justify-content: center;
}

.center-align {
	display: flex;
	justify-content: center;
	height: 100vh;
	align-items: center;
}

.form-buttons {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
}

.primary-btn {
	background-color: #de6b32 !important;
	color: white !important;
}
.primary-btn:hover {
	border-color: #de6b32 !important;
}

.ant-menu-light .ant-menu-item-selected {
	background-color: #5184c4 !important;
	color: #ffffff !important;
}

.disable_view {
	pointer-events: none;
	opacity: 0.4;
}

.image-upload > input {
	display: none;
}

.image-upload .upload_icon {
	cursor: pointer;
	font-size: 2rem;
}

.full_width {
	width: 100%;
}

.browser-btn {
	background: #de6b32;
	display: block;
	width: 142px;
	text-align: center;
	padding: 10px 15px;
	cursor: pointer;
	color: white;
}

.icon-between-text {
	display: flex;
	gap: 10px;
	align-items: center;
}

.w-30 {
	width: 30%;
}
.title-mb{
	margin-bottom: 2px !important;
}
.no-gap{
	gap: 0 !important;
}

.h-100{
	height: 100%;
}
.h-80{
	height: 80% !important;
}
.h-50{
	height: 50%;
}
.cursor_pointer{
	cursor: pointer;
}
.itemCenter {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;

}